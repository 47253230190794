<template>
    <b-card class="text-center">
        <b-alert show variant="warning" class="mx-0 mt-0 px-0">
            Insira uma imagem PNG, JPG ou GIF de até 5Mb.
        </b-alert>
        <div class="text-center">
            <b-img
                :src="usuarioLogado.caminho_foto"
                width="200"
                class="mb-2 ml-1"
                fluid
                alt="Foto do cliente"
            ></b-img>
        </div>
        <upload
            class="ml-0"
            name="foto"
            :url="`area_cliente/clientes/alterar_foto/${usuarioLogado.id}/${fotoId || ''}`"
            :accept="accept"
            placeholder="Selecione uma foto"
            @afterSend="uploadFoto"
        />
    </b-card>
</template>
<script>
import Upload from '@/shared/components/ui/Upload'
import { mapActions } from 'vuex'

export default {
    name: 'FormularioClienteFoto',
    inject: ['$validator'],
    components: {
        Upload
    },
    props: ['usuarioLogado'],
    data() {
        return {
            accept: 'image/x-png,image/gif,image/jpeg',
            fotoId: null,
        }
    },
    mounted() {
        this.carregaDados()
    },
    methods: {
        ...mapActions('usuarios', ['carregaUsuarioLogado']),
        async carregaDados() {
            const retorno = await this.$api.get(
                `area_cliente/clientes/alterar_foto`
            )
            if (retorno.status == 'success') {
                this.fotoId = retorno.data.fotoId
            } else {
                this.$dialog.error(retorno.message)
                this.$emit('fechar')    
            }
        },
        uploadFoto(res) {
            if (res.status == 'success') {
                this.$notify.success('Foto alterada com sucesso!')
                this.carregaUsuarioLogado()
                this.$emit('fechar')
            } else {
                this.$dialog.warning('Ocorreu um erro ao alterar a foto!')
            }
        }
    }
}
</script>
