import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { formataUrlBase } from '@/shared/common/functions/roteamento'
import validaAutenticacao from '../common/functions/autenticacao'

Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({
            behavior: "smooth"
        });
    }
})

router.beforeEach((to, from, next) => {
    formataUrlBase(to.fullPath)
    validaAutenticacao(to, next)
})

export default router
