import Vue from 'vue'
import App from './App.vue'
import router from './shared/router'

// plugins
import '@/shared/plugins'

// store
import store from './shared/store'

// api
import '@/shared/services/api'

// layout AdminLTE
import './shared/layouts/adminlte'

// filters
import '@/shared/common/filters'

import '@/shared/assets/css/main.scss'

// Configurações do módulo
Vue.prototype.$urlBase = '/area_cliente'

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
