import Vue from 'vue'
import Vuex from 'vuex'
import planos from './modules/planos'
import usuarios from './modules/usuarios'
import dominios from './modules/dominios'

Vue.use(Vuex)

export default new Vuex.Store({
    modules:{
        planos,
        usuarios,
        dominios,
    }
})
