<template>
    <div class="upload-wrapper">
        <template v-if="drop">
            <h5>
                {{ placeholderDrop }}
            </h5>
            <div class="text-muted mb-2">
                ou
            </div>
        </template>
        <b-button 
            :variant="buttonVariant"
            :class="buttonClass"
            :size="buttonSize"
        >
            <i :class="icon" class="mr-2"></i> {{ dataPlaceholder }}
        </b-button>
        <b-form-file
            ref="file-input"
            type="file" 
            :name="name" 
            :multiple="multiple" 
            :accept="accept" 
            @input="onInput"
            v-validate="validate"
            placeholder=""
            drop-placeholder=""
        />

        <b-row v-if="errors.has(name)">
            <span class="text-danger col-12 font-weight-bold">
                {{ validateMessage }}
            </span>
        </b-row>
    </div>
</template>
<script>

export default {
    name: 'Upload',
    props:{
        placeholder: {
            default: 'Selecione um arquivo'
        },
        placeholderDrop: {
            default: 'Solte o arquivo aqui para fazer upload...'
        },
        accept: {
            default: '*'
        },
        icon: {
            default: 'glyphicon glyphicon-folder-open'
        },
        multiple: {
            default: false
        },
        name: {
            required: true
        },
        url: {
            required: true
        },
        loading: {
            default: true
        },
        maxSize: {
            default: 10
        },
        beforeSend: {
            type: Function,
            default: () => {
                return true
            }
        },
        validate: {
            default: null
        },
        validateMessage: {
            default: ''
        },
        buttonClass: {
            default: 'mr-1'
        },
        buttonVariant: {
            default: 'primary'
        },
        buttonSize: {
            default: 'md'
        },
        drop: {
            default: false
        }
    },
    $_veeValidate: {
        validator: 'new' 
    },
    data(){
        return {
            dataPlaceholder: this.placeholder,
        }
    },
    methods: {
        onInput(files){
            if(files){
                this.$refs['file-input'].reset()
                if(!this.multiple){
                    files = [
                        files
                    ]
                }
                this.$emit('beforeSend', files)
                this.setPlaceholder(files)
                this.sendUpload(files)
            }
        },
        setPlaceholder(files){
            for (let file of files) {
                this.dataPlaceholder = file.name
            }
        },
        resetPlaceholder(){
            this.dataPlaceholder = this.$props.placeholder
        },
        async sendUpload(files){
            if(!this.beforeSend(files)){
                this.$refs['file-input'].value = []
                this.resetPlaceholder()
                return false
            }
            if(files.length == 0){
                return false
            }
            if(this.loading){
                this.$loading.show()
            }
            let maxSizeOk = true
            const formData = new FormData()
            Array.from(files).map(file => {
                if(file.size / ( 1024 * 1024 ) > this.maxSize){
                    this.$dialog.error('Arquivo muito grande. Tamanho máximo: ' + this.maxSize + 'Mb')
                    this.$loading.hide()
                    maxSizeOk = false
                    this.resetPlaceholder()
                    return false
                }
                formData.append(this.name, file)
            })
            if(!maxSizeOk){
                return false
            }
            const res = await this.$api.post(this.url, formData)
            this.$loading.hide()
            if(this.loading){
                this.$loading.hide()
            }
            this.$emit('afterSend', res)
            this.resetPlaceholder()
        }
    },
}
</script>
<style>
    /* .upload-wrapper{
        position: relative;
        display: inline-table;
        margin: 0 !important;
        width: 100%;
    }
    .upload-wrapper .custom-file-label {
        top: 0 !important;
        right: 0;
        min-width: 100%;
        min-height: 100%;
        width: 100%;
        cursor: pointer;
        font-size: 1px;
        filter: alpha(opacity=1);
        -moz-opacity: 0;
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
    }
    .upload-wrapper .button {
        cursor: pointer;
        display: inline-block;
    } */

.upload-wrapper {
    position: relative;
}
.upload-wrapper .custom-file {
    position: unset;
    height: 0;
    display: block;
}
.upload-wrapper .custom-file-input:focus ~ .custom-file-label {
    border: unset;
    box-shadow: unset;
}
.upload-wrapper .custom-file-label {
    border: none;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: transparent;
    overflow: hidden;
    text-align: center;
    position: absolute;
    cursor: pointer;
    padding: 0;
    left: 0;
    float:left;
}
.upload-wrapper .dragging {
    background: url(/img/icons/upload-icon.png) no-repeat scroll center center #f8f8f6;
    background-size: 90px;
    text-align: center;
    z-index: 20;
}
.upload-wrapper .custom-file-label::after {
    display: none;
}
.upload-wrapper .custom-file-label::after {
    display: none;
}
.upload-wrapper .form-file-text {
    display: none !important;
}
</style>
