const routes = [
    {
        path: '/area_cliente/login',
        component: () => import('../views/Login'),
        meta: {
            public: true
        },
        children: [
            {
                public: true,
                path: '/area_cliente/clientes/login',
                component: () => import('../views/Login'),
            }
        ]
    },
    {
        meta: { public: true },
        path: '/area_cliente/cadastrar_senha/:hash?',
        component: () => import('../views/CadastrarSenha'),
    },
    {
        meta: { public: true },
        path: '/area_cliente/cadastrar_dados/:hash',
        component: () => import('../views/CadastrarDados'),
    },
    {
        path: '/',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                path: '/area_cliente',
                component: () => import('../views/Inicio'),
            },
            {
                path: '/area_cliente/inicio',
                alias: ['/area_cliente/inicio', '/area_cliente/clientes/inicio', '/area_cliente/sistema/inicio'],
                component: () => import('../views/Inicio'),
            }
        ]
    }
]

export default routes
