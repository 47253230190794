import loading from '@/shared/plugins/loading'
import store from '../../store'
export default function validaAutenticacao(to, next) {
    if(!to.meta.public){
        loading.show()
        store._modules.root.context.dispatch('usuarios/carregaUsuarioLogado').then((autenticacao) => {
            loading.hide()
            if((autenticacao && autenticacao.status == 'error')){
                next('area_cliente/login?retorno=' + to.fullPath)
            }else{
                next()
            }
            loading.hide()
        })
    }else{
        next()
    }
}