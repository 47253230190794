let rotas = []
const paths = require.context('../../domains', true, /routes.js$/i)
const context = paths.keys().map(paths).map(m => m.default)

context.map((arr) => {
    if (arr) {
        arr.map((val) => {
            rotas.push(val)
        })
    }
})

const routes = [
    ...rotas
]

export default routes
