const routes = [{
    path: '',
    component: () => import('@/shared/components/layout/Interno'),
    children: [
        {
            name: 'boletosAreaCliente',
            path: '/area_cliente/movimentacoes_financeiras',
            component: () => import('../views/Listar'),
        },
        {
            name: 'visualizarAreaCliente',
            path: '/area_cliente/movimentacoes_financeiras/visualizar/:id',
            component: () => import('../views/Visualizar'),
        }
    ]
}]

export default routes
