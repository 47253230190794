const routes = [
    {
        path: '',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                path: '/area_cliente/pedidos',
                component: () => import('../views/Listar')
            },
            {
                path: '/area_cliente/pedidos/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
