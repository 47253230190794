<template>
    <div 
        class="user-panel"
    >
        <div 
            class="text-center image"
        >
            <a 
                :href="this.$urlBase + '/inicio'" 
                border="0"
            >
                <img 
                    :src="usuarioLogado.Loja.caminho_logomarca" 
                    width="150" 
                    :alt="usuarioLogado.Loja.nome"
                >                                
            </a>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            usuarioLogado: this.$usuarioLogado,
            usuarioLojas: this.$usuarioLogado.UsuarioLojas
        }
    },
}
</script>