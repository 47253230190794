<template>
    <b-nav-item-dropdown
        class="user user-menu"
        right
        ref="navdropdown"
    >
        <template #button-content>
            <div 
                class = "d-none d-sm-block"
            >
                <i class="glyphicon glyphicon-user"></i>
                <span 
                    class="ml-2"
                >
                    {{ usuarioLogado['nome'] }} 
                    <i class="fa fa-caret-down"></i>
                </span>
            </div>
            <div 
                class = "d-block d-sm-none"
            >
                <i class="glyphicon glyphicon-user"></i>
                <span class="ml-2">
                    {{ usuarioLogado['nome_abreviado'] }} 
                    <i class="fa fa-caret-down"></i>
                </span>
            </div>
        </template>
        <li 
            class="user-header bg-light-blue"
        >
            <img 
                :src="usuarioLogado['caminho_foto']" 
                class="img-circle" 
                :alt="usuarioLogado['nome']" 
            />
            <p>
                {{ usuarioLogado['nome'] }}
                <small>
                    <a 
                        @click="modalAlterarFoto = !modalAlterarFoto" 
                        href="javascript:void(0)"
                    >
                        Alterar foto
                    </a>
                </small>
            </p>
        </li>
        <li 
            class="user-footer"
        >
            <div 
                class="pull-left"
            >
                <button 
                    type="button" 
                    class="btn btn-default btn-flat"
                    @click="$store.state['usuarios'].modalAlterarSenha = !$store.state['usuarios'].modalAlterarSenha"
                >
                    Alterar senha
                </button>
            </div>
            <div 
                class="pull-right"
            >
                <a 
                    @click.prevent.stop="logout" 
                    class="btn btn-default btn-flat"
                >
                    Sair
                </a>
            </div>
        </li>

        <!-- modal alterar foto -->
        <b-modal 
            hide-footer 
            title="Alterar foto" 
            v-model="modalAlterarFoto"
            size="sm"
        >
            <alterar-foto 
                :usuarioLogado="usuarioLogado" 
                @fechar="modalAlterarFoto = false" 
            />
        </b-modal>

        <!-- modal alterar senha -->
        <b-modal 
            hide-footer 
            title="Alterar senha" 
            v-model="$store.state['usuarios'].modalAlterarSenha"
            size="sm"
        >
            <alterar-senha 
                @fechar="$store.state['usuarios'].modalAlterarSenha = false" 
            />
        </b-modal>
    </b-nav-item-dropdown>
</template>
<script>

import { mapState, mapActions } from 'vuex'
import AlterarSenha from './AlterarSenha.vue'
import AlterarFoto from './AlterarFoto.vue'

export default {
    components: {
        AlterarSenha,
        AlterarFoto
    },
    computed: {
        ...mapState('usuarios', ['usuarioLogado']),
    },
    methods:{
        ...mapActions('usuarios', {
            xLogout: 'logout'
        }),
        async logout(){
            this.$loading.show()
            const logout = await this.xLogout()
            if(logout.status == 'success'){
                location.href = this.$urlBase + '/login'
            }else{
                this.$loading.hide()
                this.$dialog.error(logout.message)
            }
        }
    },
    data() {
        return {
            modalAlterarFoto: false
        }
    }
}
</script>
<style scoped>
    .navbar-nav .user-menu .dropdown-menu {
        margin: 0;
    }
</style>