<template>
    <div class = "bg-black">
        <div class="form-box" id="login-box">
            <div class="header">
                <a :href="this.$urlBase">
                    <img 
                        :src="this.$app.caminho_logomarca" 
                        border="0" 
                        width="80%" 
                        :title="this.$app.nome" 
                        v-cloak
                    >
                </a>
            </div>
            <slot></slot>
            <div class="text-center follow-us">
                <span>Siga-nos nas redes sociais</span>
                <a 
                    v-for="rede in redesSociais" 
                    v-show="rede.habilitado" 
                    :key="rede.nome" 
                    :href="rede.link" 
                    target="_blank" 
                    class="mt-2 btn btn-circle" 
                    :class="rede.classe"
                >
                    <i :class="rede.icone"></i>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import generico from '../mixins/generico'
export default {
    mixins: [
        generico
    ],
    data(){
        return {
            carregado: false,
            redesSociais: [
                {
                    nome: 'YoutTube',
                    classe: 'bg-red',
                    icone: 'fa fa-youtube',
                    link: this.$app.youtube,
                    habilitado: this.$app.youtube ? true : false
                },
                {
                    nome: 'Facebook',
                    classe: 'bg-blue',
                    icone: 'fa fa-facebook',
                    link: this.$app.facebook,
                    habilitado: this.$app.facebook ? true : false
                },
                {
                    nome: 'Instagram',
                    classe: 'bg-purple',
                    icone: 'fa fa-instagram',
                    link: this.$app.instagram,
                    habilitado: this.$app.instagram ? true : false
                },
                {
                    nome: 'Twitter',
                    classe: 'bg-aqua',
                    icone: 'fa fa-twitter',
                    link: this.$app.twitter,
                    habilitado: this.$app.twitter ? true : false
                },
                {
                    nome: 'Linkedin',
                    classe: 'bg-blue',
                    icone: 'fa fa-instagram',
                    link: this.$app.linkedin,
                    habilitado: this.$app.linkedin ? true : false
                },
            ]
        }
    },
}
</script>
<style scoped>
    .bg-black {
        background-color: #222222 !important;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .follow-us span{
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: bold;
    }
</style>