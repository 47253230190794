<template>
    <div id="app">
        <Dialog/>
        <Notify/>
        <Modal/>
        <router-view v-if="carregado"></router-view>
    </div>
</template>
<script>
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import Dialog from '@/shared/components/ui/Dialog'
import Notify from '@/shared/components/ui/Notify'
import Modal from '@/shared/components/ui/Modal'
export default {
    components: {
        Dialog, Notify, Modal
    },
    mounted(){
        this.carregaDominio()
    },
    computed: {
        ...mapState('planos', ['empresaPlano']),
        ...mapState('dominios', ['dominio']),
        ...mapState('usuarios', ['usuarioLogado']),
        carregado(){
            if(this.dominio){
                this.atualizaTitulo(this.dominio)
                this.atualizaIcone(this.dominio)
            }
            return this.dominio
        }
    },
    watch:{
        dominio(dominio){
            Vue.prototype.$app = dominio
        },
        usuarioLogado(usuarioLogado){
            Vue.prototype.$usuarioLogado = usuarioLogado
        },
        empresaPlano(empresaPlano){
            Vue.prototype.$empresaPlano = empresaPlano
        },
    },
    methods: {
        ...mapActions('dominios', ['carregaDominio']),
        atualizaTitulo(dados){
            document.title = dados.nome || 'Carregando...'
        },
        atualizaIcone(dados){
            document.querySelector('link[rel="icon"]').href = dados.caminho_favicon || ''
        },
    }
}
</script>