<template>
    <div v-if="carregado" class="skin-blue">
        <Topo/>
        <div class="wrapper row-offcanvas row-offcanvas-left">
            <aside 
                class="left-side sidebar-offcanvas" 
            >
                <section class="sidebar">
                    <Loja/>
                    <Menu/>
                </section>
            </aside>
            <aside class="right-side">
                <slot></slot>
            </aside>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import generico from '../mixins/generico'
import interno from '../mixins/interno'
import Topo from './Topo'
import Loja from './Loja'
import Menu from './Menu'
import { mapState, mapActions } from 'vuex'
Vue.mixin(interno)
export default {
    components: {
        Topo, Loja, Menu
    },
    mixins: [
        generico, interno
    ],
    mounted: function(){
        this.atualizaDadosNavegacao()
    },
    computed: {
        ...mapState('usuarios', ['usuarioLogado']),
        carregado(){
            return true
        },
    },
    watch:{
        '$route'() {
            this.atualizaTitulo()
        }
    },
    methods: {
        ...mapActions('usuarios', ['carregaUsuarioLogado']),
        atualizaDadosNavegacao(){
            let ativo = true
            window.onfocus = () => {
                if(ativo == false){
                    this.carregaUsuarioLogado()
                }
                ativo = true
            }
            window.onblur = () => {
                ativo = false
            }
        },
        atualizaTitulo(){
            document.title = this.$app.nome
            let i = 0
            const limite = 10
            while (i <= limite) {
                setTimeout(() => {
                    const h1 = document.getElementsByTagName('h1')
                    if (h1.length && h1[0] !== undefined) {
                        document.title = (`${document.getElementsByTagName('h1')[0].innerText} - ${this.$app.nome}`)
                        i = limite
                    }
                }, i * 500)
                i++
            }
        },
    }
}
</script>
