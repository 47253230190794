<template>
    <header class="header">
        <a 
            :href="this.$urlBase + '/inicio'" 
            class="logo"
        >
            <img 
                :src="this.$app.caminho_logomarca" 
                border="0" 
                width="95%" 
                :title="this.$app.nome" 
                v-cloak
            >
        </a>
        <nav 
            class="navbar navbar-expand-lg" 
            role="navigation"
        >
            <a 
                href="#" 
                @click.prevent="alternarBarraLateral" 
                class="navbar-btn sidebar-toggle" 
                data-toggle="offcanvas" 
                role="button"
            >
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </a>
            <div 
                class="navbar-right"
            >
                <ul 
                    class="nav navbar-nav m-0"
                >
                    <usuario />
                </ul>
            </div>
        </nav>
    </header>
</template>
<script>
import Usuario from './Usuario'
export default {
    components: {
        Usuario
    },
    watch: {
        $route(to, from) {
            if(to.name != from.name){
                if(this.$isMobile() || to.meta.fullScreen){
                    this.escondeMenu()
                }else{
                    this.mostraMenu()        
                }
            }
        }
    },
    methods:{
        alternarBarraLateral(){
            const leftSide = document.querySelector('.left-side')
            if(
                leftSide.classList.contains('collapse-left') 
            ){
                this.mostraMenu()  
            }else{
                this.escondeMenu()   
            }
        },
        mostraMenu(){
            document.querySelector('.row-offcanvas').classList.add('relative')
            document.querySelector('.row-offcanvas').classList.add('active')
            document.querySelector('.left-side').classList.remove('collapse-left')
            document.querySelector('.right-side').classList.remove('strech')
            const botaoOperacoes = document.querySelector('.botao-operacoes')
            if(botaoOperacoes){
                botaoOperacoes.classList.add('d-none')
            }
        },
        escondeMenu(){
            document.querySelector('.row-offcanvas').classList.remove('relative')
            document.querySelector('.row-offcanvas').classList.remove('active')
            document.querySelector('.left-side').classList.add('collapse-left')
            document.querySelector('.right-side').classList.add('strech')
            const botaoOperacoes = document.querySelector('.botao-operacoes')
            if(botaoOperacoes){
                botaoOperacoes.classList.remove('d-none')
            }
        },
    }
}
</script>
<style>
    header.header > .dropdown { 
        float: left;
    }
    header.header .dropdown > a{ 
        padding: 15px 15px 8px 15px;
        display: block;
    }
    header.header .dropdown-toggle:after { 
        content: none 
    } 
    header.header .navbar{
        position: relative;
        min-height: 50px;
        display: block;
        flex-wrap: unset;
        padding: 0;
    }
    header.header .navbar-nav{
        flex-direction: unset
    }
    header.header .navbar-right{
        float: right;
    }
    header.header .navbar-nav .user-menu .dropdown-menu{
        padding: 0;
    }
</style>