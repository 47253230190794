import Vue from 'vue'
import $api from '@/shared/services/api'

export default {
    namespaced: true,
    state: {
        dominio: null,
    },
    getters: {
        pegaDominio(state){
            return state.dominio
        },
    },
    mutations: {
        atualizaDominio(state, dominio){
            state.dominio = dominio
        },
    },
    actions: {
        async carregaDominio({ commit }){
            const retorno = await $api.cGet('/dominios/dados')
            if(retorno.status == 'success'){
                commit('atualizaDominio', retorno.data.Dominio)
            }else{
                Vue.prototype.$dialog.error(retorno.message)
            }
        }
    },
}
