import { carregaScriptChat } from '@/shared/plugins/chat'
export default {
    mounted() {
        carregaScriptChat()
        this.aplicarCoresCss(this.$app.cor_primaria, this.$app.cor_secundaria)
    },
    methods: {
        aplicarCoresCss(corPrimaria, corSecundaria){
            const estilos = [
                {
                    elemento: '#bem-vindo .modal-header, #curtir-facebook .modal-header',
                    propriedades: [
                        'background: #' + corPrimaria,
                        'color: #FFFFFF'
                    ]
                },
                {
                    elemento: '.pace .pace-progress',
                    propriedades: [
                        'background: #' + corSecundaria,
                    ]
                },
                {
                    elemento: 'a',
                    propriedades: [
                        'color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: 'a:hover, a:active, a:focus',
                    propriedades: [
                        'color: #' + corSecundaria,
                    ]
                },
                {
                    elemento: '.text-primary',
                    propriedades: [
                        'color: #' + corPrimaria + ' !important',
                    ]
                },
                {
                    elemento: '.text-primary:hover',
                    propriedades: [
                        'color: #' + corSecundaria,
                    ]
                },
                {
                    elemento: '.cor-padrao',
                    propriedades: [
                        'color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.flash-tab',
                    propriedades: [
                       'background: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.dropdown-menu > li > a:hover',
                    propriedades: [
                        'background-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus',
                    propriedades: [
                        'background-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.navbar-nav > .user-menu > .dropdown-menu > li.user-header',
                    propriedades: [
                        'background: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.box.box-primary',
                    propriedades: [
                        'border-top-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.box.box-solid.box-primary > .box-header',
                    propriedades: [
                        'background: #' + corPrimaria,
                        'background-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.box .todo-list > li.primary',
                    propriedades: [
                        'border-left-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.card-header.bg-primary',
                    propriedades: [
                        'background: #' + corPrimaria + ' !important',
                        'background-color: #' + corPrimaria + ' !important',
                    ]
                },
                {
                    elemento: '.bg-light-blue',
                    propriedades: [
                        'background-color: #' + corPrimaria + ' !important',
                    ]
                },
                {
                    elemento: '.bg-olive',
                    propriedades: [
                        'background-color: #' + corPrimaria + ' !important',
                    ]
                },
                {
                    elemento: '.text-light-blue',
                    propriedades: [
                        'color: #' + corPrimaria + ' !important',
                    ]
                },
                {
                    elemento: '.text-olive',
                    propriedades: [
                        'color: #' + corPrimaria  + ' !important',
                    ]
                },
                {
                    elemento: '.btn.btn-primary',
                    propriedades: [
                        'background-color: #' + corPrimaria,
                        'border-color: #' + corSecundaria,
                    ]
                },
                {
                    elemento: '.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary',
                    propriedades: [
                        'background-color: #' + corSecundaria,
                        'border-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.nav.nav-pills > li.active > a, .nav.nav-pills > li.active > a:hover',
                    propriedades: [
                        'border-top-color: #' + corPrimaria,
                        'color: #444',
                    ]
                },
                {
                    elemento: '.nav.nav-pills.nav-stacked > li.active > a, .nav.nav-pills.nav-stacked > li.active > a:hover',
                    propriedades: [
                        'border-left-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.nav-tabs-custom > .nav-tabs > li.active',
                    propriedades: [
                        'border-top-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.nav-pills .nav-link.active, .nav-pills .show>.nav-link',
                    propriedades: [
                        'background-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.form-box .header',
                    propriedades: [
                        'background: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.skin-blue .navbar',
                    propriedades: [
                        'background-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.form-control:hover,.form-control:focus,.form-control-table:hover,.form-control-table:focus',
                    propriedades: [
                        'border-color: #' + corPrimaria + ' !important',
                    ]
                },
                {
                    elemento: '.progress-bar-light-blue, .progress-bar-primary',
                    propriedades: [
                        'background-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: 'fieldset legend',
                    propriedades: [
                        'color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: 'table.calendario tr td .compromissos',
                    propriedades: [
                        'background: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.btn.btn-primary',
                    propriedades: [
                        'border-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.btn.btn-primary:hover, .btn.btn-primary:active, .btn.btn-primary.hover',
                    propriedades: [
                        'background-color: #' + corPrimaria + ' !important',
                        'border-color: #' + corPrimaria + ' !important',
                    ]
                },
                {
                    elemento: '.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle, .dropdown-item.active, .dropdown-item:active',
                    propriedades: [
                        'background-color: #' + corPrimaria + ' !important',
                        'border-color: #' + corPrimaria + ' !important',
                    ]
                },
                {
                    elemento: '.skin-blue .logo',
                    propriedades: [
                        'background-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: 'div.token-input-dropdown ul li.token-input-selected-dropdown-item',
                    propriedades: [
                        'background: #' + corPrimaria + '!important',
                    ]
                },
                {
                    elemento: '.text-info',
                    propriedades: [
                        'color:#' + corPrimaria,
                    ]
                },
                {
                    elemento: '.text-info:hover',
                    propriedades: [
                        'color:#' + corSecundaria,
                    ]
                },
                {
                    elemento: '.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus',
                    propriedades: [
                        'background-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.wizard li.active span.round-tab',
                    propriedades: [
                        'border: 2px solid #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.wizard li.active span.round-tab i',
                    propriedades: [
                        'color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.wizard li:after',
                    propriedades: [
                        'border-bottom-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.wizard li.active:after',
                    propriedades: [
                        'border-bottom-color: #' + corPrimaria,
                    ]
                },
                {
                    elemento: '.ui-button.ui-state-active:hover',
                    propriedades: [
                        'background: #' + corPrimaria + ' !important',
                    ]
                },
                {
                    elemento: '.autocomplete ul.results li:hover',
                    propriedades: [
                        'background: #' + corPrimaria + ' !important',
                    ]
                },
                {
                    elemento: '.autocomplete ul.results li.active',
                    propriedades: [
                        'background: #' + corPrimaria + ' !important',
                    ]
                },
            ]
            estilos.forEach((estilo) => {
                estilo.propriedades.forEach((propriedade) => {
                    let elemento = document.createElement('style')
                    elemento.type = 'text/css'
                    document.getElementsByTagName('head')[0].appendChild(elemento)
                    if(!(elemento.sheet || {}).insertRule){
                        (elemento.styleSheet || elemento.sheet).addRule(estilo.elemento, propriedade)
                    }else{
                        elemento.sheet.insertRule(estilo.elemento + "{" + propriedade + "}",0)
                    }
                })
            })
        }
    },
}