<template>
    <ul 
        v-if="menus.length" 
        class="sidebar-menu"
    >
        <li>
            <b-link
                to="/area_cliente/inicio"
            >
                <i 
                    class="mr-1 fa fa-dashboard"
                /> 
                Início
            </b-link>
        </li>
        <li 
            v-for="(menu, i) in menus"
            :key="i"
        >
            <b-link
                :to="menu.url"
            >
                <i 
                    class="mr-1"
                    :class="menu.icone"
                /> 
                {{ menu.nome }}
            </b-link>
        </li>
        <li>
            <b-link
                @click="$store.state['usuarios'].modalAlterarSenha = !$store.state['usuarios'].modalAlterarSenha"
            >
                <i 
                    class="mr-1 fa fa-key"
                /> 
                Alterar senha
            </b-link>
        </li>
        <li>
            <b-link
                @click="logout" 
            >
                <i 
                    class="mr-1 fa fa-power-off"
                /> 
                Sair
            </b-link>
        </li>
    </ul>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    data(){
        return {
            menus: [],
            servicos: {
                'OS': {
                    nome: 'Ordens de Serviços',
                    icone: 'fa icon-websitebuilder',
                    url: '/area_cliente/ordens_servicos'
                },
                'PE': {
                    nome: 'Pedidos',
                    icone: 'fa icon-shopping',
                    url: '/area_cliente/pedidos'
                },
                'BO': {
                    nome: 'Boletos',
                    icone: 'fa fa-barcode',
                    url: '/area_cliente/movimentacoes_financeiras'
                },
                'AT': {
                    nome: 'Atendimentos',
                    icone: 'fa fa-phone',
                    url: '/area_cliente/atendimentos'
                },
                'NF': {
                    nome: 'NF-e',
                    icone: 'fa  fa-shopping-cart',
                    url: '/area_cliente/notas_fiscais'
                },
                'NFS': {
                    nome: 'NFS-e',
                    icone: 'fa fa-file-text-o',
                    url: '/area_cliente/notas_fiscais_servicos'
                },
                'NFC': {
                    nome: 'NFC-e',
                    icone: 'fa fa-qrcode',
                    url: '/area_cliente/notas_fiscais_consumidores'
                }
            }
        }
    },
    mounted() {
        const servicos = this.$usuarioLogado.webdesk_servicos.split(':')
        if(servicos.length){
            servicos.map(servico => {
                this.menus.push(this.servicos[servico])
            })
        }
    },
    methods:{
        ...mapActions('usuarios', {
            xLogout: 'logout'
        }),
        async logout(){
            this.$loading.show()
            const logout = await this.xLogout()
            if(logout.status == 'success'){
                location.href = this.$urlBase + '/login'
            }else{
                this.$loading.hide()
                this.$dialog.error(logout.message)
            }
        }
    }
}
</script>