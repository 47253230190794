<template>
    <div>
        <b-card>
            <b-form-row>
                <b-form-group
                    label="Senha atual"
                    label-for="label-sr-only"
                    class="col-sm-12 required"
                >
                    <b-form-input
                        v-model="data.Cliente.webdesk_senha_atual"
                        name="senha_atual"
                        type="password"
                        v-validate="'required|min:6'"
                        :class="[
                            {
                                'error': errors.has('senha_atual')
                            }
                        ]"
                    />
                    <b-form-invalid-feedback
                        v-if="errors.has('senha_atual')"
                        class="d-block"
                    >
                        {{ errors.first('senha_atual') }}
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                    label="Nova senha"
                    label-for="label-sr-only"
                    class="col-sm-12 required"
                >
                    <b-form-input
                        ref="password"
                        v-model="data.Cliente.webdesk_senha"
                        name="senha"
                        v-validate="'required|min:6'"
                        type="password"
                        :class="[
                            {
                                'error': errors.has('senha')
                            }
                        ]"
                    />
                    <b-form-invalid-feedback
                        v-if="errors.has('senha')"
                        class="d-block"
                    >
                        {{ errors.first('senha') }}
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                    label="Confirme senha"
                    label-for="label-sr-only"
                    class="col-sm-12 required"
                >
                    <b-form-input
                        v-model="data.Cliente.webdesk_confirme_senha"
                        name="confirme_senha"
                        type="password"
                        v-validate="'required|confirmed:password|min:6'"
                        :class="[
                            {
                                'error': errors.has('confirme_senha')
                            }
                        ]"
                    />
                    <b-form-invalid-feedback
                        v-if="errors.has('confirme_senha')"
                        class="d-block"
                    >
                        {{ errors.first('confirme_senha') }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-form-row>
        </b-card>
        <div class="mt-2">
            <b-button
                id="botaoFormularioSucesso"
                variant="success"
                class="mr-2"
                @click="salvarDados"
            >
                <i class="glyphicon glyphicon-ok"></i>
                Alterar
            </b-button>
            <b-button
                @click="$emit('fechar')"
                id="botaoFormularioCancelar"
                variant="danger"
            >
                <i class="glyphicon glyphicon-remove"></i>
                Cancelar
            </b-button>
        </div>
    </div>
</template>
<script>
export default {
    $_veeValidate: {
        validator: 'new'
    },
    props: ['show'],
    data() {
        return {
            data: {
                Cliente: {
                    webdesk_senha_atual: '',
                    webdesk_senha: '',
                    webdesk_confirme_senha: ''
                }
            }
        }
    },
    mounted() {
        this.carregaDados()
    },
    methods: {
        async carregaDados() {
            const retorno = await this.$api.get(
                `area_cliente/clientes/alterar_senha`
            )
            if (retorno.status != 'success') {
                this.$dialog.error(retorno.message)
                this.$emit('fechar')
            }
        },
        async salvarDados(){
            const validado = await this.$validator.validateAll()
            if(!validado){
                return false
            }
            const retorno = await this.$api.post(
                `area_cliente/clientes/alterar_senha`,
                this.data
            )
            if (retorno.status == 'success') {
                this.$notify.success(retorno.message)
                this.$emit('fechar')
            } else {
                this.$dialog.error(retorno.message)
            }
        },
    }
}
</script>
